<template>
  <v-container fluid>
    <v-row dense>
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto"
          flat
        >
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1918.7525444146795!2d108.3242387!3d15.8825882!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31420e7bad683e45%3A0x8a8bf03c722e96ca!2sSuna%20Spa!5e0!3m2!1sen!2s!4v1662976190987!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <v-card-title>
            Suna Spa
          </v-card-title>
          <v-card-subtitle>
            Beauty Center
          </v-card-subtitle>
          <v-card-text>
            <div class="d-flex flex-column mb-6">
              <span>{{ $t('control.addr.addr1') }}, {{ $t('control.addr.addr2') }}, {{ $t('control.addr.addr3') }}</span>
              <span>{{ $t('contact.time.open') }}</span>
              <span>{{ $t('contact.time.booking') }}</span>
              <span
                class="d-flex flex-row"
              >
                <v-icon small>mdi-phone</v-icon>
                <span
                  v-for="phone in sunaContact.phone"
                  :key="phone"
                  class="pa-2"
                >{{ phone }}</span>
              </span>
              <span
                class="d-flex flex-row"
              >
                <v-icon small>mdi-email</v-icon>
                <span
                  v-for="email in sunaContact.email"
                  :key="email"
                  class="pa-2"
                >{{ email }}</span>
              </span>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              variant="outlined"
              @click="openNewTab"
            >
              <span class="hidden-sm-and-down">Google Maps</span>
              <v-icon icon="mdi-map-marker"/>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sunaContact } from '@/appConfig'

export default ({
  name: 'ContactView',
  data () {
    return {
      sunaContact: sunaContact
    }
  },
  methods: {
    openNewTab () {
      window.open('https://goo.gl/maps/UZS6Rs4HBpDSqp617', '_blank')
    }
  }
})
</script>
