<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(sunaPackage, packageName) in sunaPackages"
        :key="packageName"
        cols="12"
      >
        <v-card
        >
          <div
            class="d-flex flex-no-wrap justify-space-between"
          >
            <div>
              <v-card-title
                class="text-h5"
              >
                {{ $t('packages.'+packageName+'.title') }}
              </v-card-title>

              <v-card-text>
                <div
                  v-for="price in sunaPackage.prices"
                  :key="price"
                  class="my-4 text-subtitle-1"
                >
                  <v-icon>mdi-currency-usd</v-icon>
                  {{
                    numberWithCommas($t('packages.'+packageName+'.price.'+price+'.price'), $i18n.locale)
                    + $t('packages.currency')
                    + ' - '
                    + $t('packages.'+packageName+'.price.'+price+'.title')
                  }}
                </div>
                <div
                  v-if="$t('packages.'+packageName+'.desc') !== ''"
                >
                  {{ $t('packages.'+packageName+'.desc') }}
                </div>
              </v-card-text>
            </div>
            <v-avatar
              class="ma-3"
              size="125"
              tile
            >
              <v-img
                :src="require('@/assets/suna-pkg.png')"
              ></v-img>
            </v-avatar>
          </div>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Services</v-card-title>

          <v-card-text
            class="d-flex flex-wrap"
          >
            <v-chip
              class="ma-1"
              v-for="(service, i) in flattenServicePrices(sunaPackage.serviceGroups)"
              :key="i"
            >
              {{
                $t('services.'+service.groupName+'.children.'+service.serviceName+'.title') +
                ' - '+
                $t('services.'+service.groupName+'.children.'+service.serviceName+'.price.'+service.price+'.title')
              }}
            </v-chip>
            <v-chip
              class="ma-1"
              v-for="freeService in sunaPackage.freeServices"
              :key="freeService"
            >
              free {{ $t('packages.free.'+freeService) }}
            </v-chip>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ml-2 mt-5"
              outlined
              rounded
              small
              @click="showDetailPackage[packageName] = !showDetailPackage[packageName]"
            >
              {{ showDetailPackage[packageName] ? 'Hide' : 'Details' }}
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="showDetailPackage[packageName]">
              <v-row
                dense
                class="pa-3"
              >
                <v-col
                  v-for="(serviceGroup,i) in sunaPackage.serviceGroups"
                  :key="i"
                  cols="12"
                  md="3"
                  class="d-flex flex-column"
                >
                  <SunaService
                    :serviceGroupName="serviceGroup.serviceGroupName"
                    :serviceName="serviceGroup.serviceName"
                    :prices="serviceGroup.prices"
                    serviceImgHeight="200px"
                  ></SunaService>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SunaService from '@/components/SunaService.vue'
import {sunaPackages, sunaGroupServices, numberWithCommas} from '@/appConfig'

export default ({
  name: 'PackagesView',
  data () {
    const show = {}
    Object.keys(sunaPackages).forEach(packageName => {
      show[packageName] = false
    })
    return {
      sunaGroupServices: sunaGroupServices,
      sunaPackages: sunaPackages,
      showDetailPackage: show
    }
  },
  methods: {
    flattenServicePrices (serviceGroups) {
      let serviceNames = []
      serviceGroups.forEach(serviceGroup => {
        serviceGroup.prices.forEach(price => {
          serviceNames.push({
            groupName:serviceGroup.serviceGroupName,
            serviceName: serviceGroup.serviceName,
            price: price
          })
        })
      })
      return serviceNames
    },
    numberWithCommas
  },
  components: {
    SunaService
  }
})
</script>
