<template>
  <v-col cols="auto">
    <v-select
      v-model="lang"
      :items="languages"
      item-text="title"
      item-value="lang"
      class="align-center"
      hide-details
      flat
      outlined
      dense
      single-line
      @change="changeLanguage(lang)"
    >
      <template v-slot:prepend>
        <v-icon>mdi-web</v-icon>
      </template>
      <template v-slot:selection="{ item }">
        <country-flag
          :country="item.country"
          size="normal"
          class="ma-0"
        />
      </template>
      <template v-slot:item="{ item }">
        <v-row no-gutters align="center">
          <country-flag
            :country="item.country"
            size="normal"
          />
          <v-spacer></v-spacer>
          <span>{{ item.title }}</span>
        </v-row>
      </template>
    </v-select>
  </v-col>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { loadLanguageAsync } from '@/lang'

export default ({
  name: 'LocaleSwitcher',
  data () {
    const codes = {
      en: 'us',
      vi: 'vn',
      ko: 'kr'
    }
    return {
      lang: window.APPCONFIG.VUE_APP_I18N_LOCALE,
      languages: [
        { lang: 'en', country: 'us', title: 'English' },
        { lang: 'vi', country: 'vn', title: 'Tiếng Việt' },
        { lang: 'ko', country: 'kr', title: '한국어' }
      ],
      lang2Country: codes
    }
  },
  methods: {
    changeLanguage() {
      loadLanguageAsync(this.lang)
    }
  },
  components: {
    CountryFlag
  }
})
</script>
<style>
.v-select__selections input {
  display: none;
}
</style>
