export const sunaGroupServices = {
  massage: {
    suna: ['60', '90'],
    aroma: ['60', '90'],
    hotstone: ['60', '90'],
    thai: ['60', '90'],
    foot: ['60'],
    zone: ['60'],
  },
  face: {
    face: ['60']
  },
  body: {
    body: ['60fresh', '60mineral', '60coffee', '60lemon']
  },
  nail: {
    nail: ['manicure', 'pedicure', '60dexPedicure', '60dexManicure', 'color', 'design', 'french', 'feet', 'gel']
  },
  wax: {
    wax: ['halfLeg', 'fullLeg', 'halfArm', 'fullArm', 'bikini', 'underArm']
  }
}

export const sunaPackages = {
  suna: {
    serviceGroups: [
      { serviceGroupName: 'massage', serviceName: 'foot', prices: ['60'] },
      { serviceGroupName: 'massage', serviceName: 'zone', prices: ['60'] },
      { serviceGroupName: 'face', serviceName: 'face', prices: ['60'] }
    ],
    freeServices: ['juice'],
    prices: ['120']
  },
  sunny: {
    serviceGroups: [
      { serviceGroupName: 'massage', serviceName: 'foot', prices: ['60'] },
      { serviceGroupName: 'face', serviceName: 'face', prices: ['60'] },
      { serviceGroupName: 'nail', serviceName: 'nail', prices: ['60dexPedicure'] }
    ],
    freeServices: ['juice'],
    prices: ['150']
  },
  tranquil: {
    serviceGroups: [
      { serviceGroupName: 'massage', serviceName: 'aroma', prices: ['60'] },
      { serviceGroupName: 'face', serviceName: 'face', prices: ['60'] },
      { serviceGroupName: 'body', serviceName: 'body', prices: ['60fresh'] },
      { serviceGroupName: 'nail', serviceName: 'nail', prices: ['manicure', '60dexPedicure'] }
    ],
    freeServices: ['juice', 'food'],
    prices: ['225']
  }
}

export const sunaContact = {
  phone: ['0905861414', '0799482294'],
  email: ['sunaspahoian616@gmail.com'],
  zalo: [],
  kakaotalk: [],
  line: [],
  whatsapp: [],
  telegram: [],
  facebook: [],
  instagram: []
}

export function numberWithCommas(num, lang) {
  let separator = ','
  if (lang==='vi') {
    separator = '.'
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}