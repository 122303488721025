<template>
  <v-card
    class="flex d-flex flex-column"
  >
    <v-img
      :src="require('@/assets/'+serviceGroupName+'-'+serviceName+'.jpg')"
      :height="serviceImgHeight"
    ></v-img>
    <v-card-title>
      {{ $t('services.'+serviceGroupName+'.children.'+serviceName+'.title') }}
    </v-card-title>

    <v-card-text
      class="flex"
    >
      {{ $t('services.'+serviceGroupName+'.children.'+serviceName+'.desc') }}
    </v-card-text>

    <v-expand-transition>
      <v-container class="grey lighten-5 d-flex flex-wrap">
        <v-chip
          class="ma-1"
          v-for="price in prices"
          :key="price"
        >
          <v-icon>mdi-currency-usd</v-icon>
          {{
            numberWithCommas($t('services.'+serviceGroupName+'.children.'+serviceName+'.price.'+price+'.price'), $i18n.locale)
            + $t('services.currency')
            + ' - '
            + $t('services.'+serviceGroupName+'.children.'+serviceName+'.price.'+price+'.title')
          }}
        </v-chip>
      </v-container>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {numberWithCommas} from '@/appConfig'

export default ({
  name: 'SunaService',
  props: {
    serviceGroupName: String,
    serviceName: String,
    prices: Array,
    serviceImgHeight: String
  },
  methods: {
    numberWithCommas
  }
})
</script>

<style scoped>
</style>
