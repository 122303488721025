import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages (lang) {
  const locales = require.context('@/lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  messages[lang] = {}
  let localeRegex = new RegExp(`\\/(${lang})\\/([A-Za-z0-9-_]+)\\.`, 'i')
  locales.keys().forEach(key => {
    const matched = key.match(localeRegex)
    if (matched && matched.length > 2) {
      const component = matched[2]
      messages[lang][component] = locales(key)
    }
  })
  return messages
}

const loadedLanguages = [window.APPCONFIG.VUE_APP_I18N_LOCALE] // preloaded default language

export const i18n = new VueI18n({
  locale: window.APPCONFIG.VUE_APP_I18N_LOCALE,
  fallbackLocale: window.APPCONFIG.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(window.APPCONFIG.VUE_APP_I18N_LOCALE)
})

export function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  let messages = loadLocaleMessages(lang)
  i18n.setLocaleMessage(lang, messages[lang])
  loadedLanguages.push(lang)
  return setI18nLanguage(lang)
}