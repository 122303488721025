<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(product, i) in products"
        :key="i"
        class="d-flex child-flex"
        cols="12"
        md="4"
      >
        <v-img
          :src="product.src"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default ({
  name: 'ProductsView',
  data () {
    return {
      products: [
        { src: require('@/assets/product-1.png'), title: '', desc: '' },
        { src: require('@/assets/product-2.png'), title: '', desc: '' },
        { src: require('@/assets/product-3.png'), title: '', desc: '' }
      ]
    }
  }
})
</script>
