import Vue from 'vue'
import Router from 'vue-router'

import LandingView from '@/views/LandingView.vue'
import ServicesView from '@/views/ServicesView.vue'
import PackagesView from '@/views/PackagesView.vue'
import ProductsView from '@/views/ProductsView.vue'
import GalleryView from '@/views/GalleryView.vue'
import ContactView from '@/views/ContactView.vue'
import ReservationView from '@/views/ReservationView.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/packages',
    name: 'packages',
    component: PackagesView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: ReservationView
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  routes
})

export default router
