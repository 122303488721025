<template>

  <v-app>

    <v-navigation-drawer
      app
      v-model="sidebar"
      bottom
      temporary
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ $t('control.drawer.subHeader') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          link
          active-color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >{{ $t('control.appBar.'+item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      class="bg-blue-grey-darken-1 text-white"
    >
      <v-app-bar-nav-icon @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <locale-switcher></locale-switcher>

      <v-spacer></v-spacer>

      <v-btn-toggle
        borderless
        v-model="btnToggle"
        variant="text"
      >
        <v-btn
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          class="text-white"
        >
          <span class="hidden-sm-and-down">{{ $t('control.appBar.'+item.title) }}</span>
          <v-icon end>{{ item.icon }}</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-app-bar>

    <v-main>
      <!-- <v-container class="pa-0" fluid fill-height> -->
        <router-view></router-view>
      <!-- </v-container> -->
    </v-main>

    <v-footer
      app
      class="pa-0 bg-blue-grey-darken-1 text-white align-center"
    >

      <v-icon>mdi-copyright</v-icon>
      AnhThi Beauty
      <v-spacer></v-spacer>
      <v-icon>mdi-map-marker-circle</v-icon>
      {{ $t('control.addr.addr1') + ', ' + $t('control.addr.addr2') }}
      <v-spacer></v-spacer>
      <v-btn
        v-for="icon in socialMediaIcons"
        :key="icon"
        class="mx-4 text-white"
        plain
        small
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </v-footer>

  </v-app>

</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'

export default ({
  name: 'App',
  data () {
    return {
      appTitle: 'Suna Spa',
      btnToggle: null,
      sidebar: false,
      group: null,
      menuItems: [
        { title: 'home', path: '/', icon: 'mdi-home' },
        { title: 'services', path: '/services', icon: 'mdi-hand-clap' },
        { title: 'packages', path: '/packages', icon: 'mdi-package-variant' },
        { title: 'products', path: '/products', icon: 'mdi-spa' },
        { title: 'gallery', path: '/gallery', icon: 'mdi-image-multiple' },
        { title: 'reservation', path: '/reservation', icon: 'mdi-notebook-plus' },
        { title: 'contact', path: '/contact', icon: 'mdi-information' }
      ],
      socialMediaIcons: [
        'mdi-facebook',
        'mdi-instagram'
      ]
    }
  },
  watch: {
    group () {
      this.sidebar = false
    }
  },
  components: {
    LocaleSwitcher
  }
})
</script>

<i18n>
  <! -- define more locale here -->
</i18n>

<style></style>
