<template>
  <v-img
    class="white--text"
    :src="require('@/assets/suna-bg.jpg')"
    gradient="
      90deg,
      #03050a 0%,
      rgba(3, 5, 10, 0.9) 38%,
      rgba(3, 5, 10, 0.85) 62%,
      rgba(3, 5, 10, 0.8) 83%,
      rgba(3, 5, 10, 0.75) 100%"
    height="100%"
    eager
  >
    <div class="text-h4 ma-10 text-center">
      {{ $t('reservation.message') }}
    </div>
    <div class="text-h6 ml-10 ma-6">
      <font-awesome-icon icon="fa-solid fa-phone" />
      <span
        v-for="phone in sunaContact.phone"
        :key="phone"
        class="flex pa-2"
        >{{ phone }}
      </span>
    </div>
    <div class="text-h6 ml-10 ma-6"><font-awesome-icon icon="fa-brands fa-facebook-messenger" /></div>
    <div class="text-h6 ml-10 ma-6"><font-awesome-icon icon="fa-brands fa-line" /></div>
    <div class="text-h6 ml-10 ma-6"><font-awesome-icon icon="fa-brands fa-whatsapp" /></div>
    <div class="text-h6 ml-10 ma-6"><icon-base icon-name="kakaotalk" width="20" height="20"><icon-kakao-talk /></icon-base></div>
    <div class="text-h6 ml-10 ma-6"><icon-base icon-name="zalo" width="20" height="20"><icon-zalo /></icon-base></div>
    <div class="text-h6 ml-10 ma-6">
      <font-awesome-icon icon="fa-solid fa-envelope" />
      <span
      v-for="email in sunaContact.email"
      :key="email"
      class="pa-2"
      >{{ email }}</span>
    </div>
  </v-img>
</template>

<script>
import { sunaContact } from '@/appConfig'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger, faLine, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

/* import customized icon svg */
import IconBase from '@/components/IconBase.vue'
import IconKakaoTalk from '@/components/icons/IconKakaoTalk.vue'
import IconZalo from '@/components/icons/IconZalo.vue'

/* add icons to the library */
library.add(faEnvelope, faPhone, faFacebookMessenger, faLine, faWhatsapp)


export default ({
  name: 'ReservationView',
  data () {
    return {
      sunaContact: sunaContact
    }
  },
  components: {
    FontAwesomeIcon,
    IconBase,
    IconKakaoTalk,
    IconZalo
  }
})
</script>

<style lang="scss" scoped>
</style>
