<template>
  <v-img
    class="white--text"
    :src="require('@/assets/suna-bg.jpg')"
    gradient="
      90deg,
      #03050a 0%,
      rgba(3, 5, 10, 0.9) 38%,
      rgba(3, 5, 10, 0.85) 62%,
      rgba(3, 5, 10, 0.8) 83%,
      rgba(3, 5, 10, 0.75) 100%"
    height="100%"
    eager
  >
    <div class="ma-sm-10"><p class="text-h4 text-center">{{ $t('landing.para1') }}</p></div>
    <div class="ma-sm-10"><p class="text-h4 text-center">{{ $t('landing.para2') }}</p></div>
    <div class="mt-sm-10 d-flex flex-column justify-center">
      <div class="text-h6 mx-sm-16"><p class="text-justify">{{ $t('landing.para3') }}</p></div>
      <div class="text-h6 mx-sm-16"><p class="text-justify">{{ $t('landing.para4') }}</p></div>
      <div class="text-h6 mx-sm-16"><p class="text-justify">{{ $t('landing.para5') }}</p></div>
    </div>
  </v-img>

</template>

<script>
export default ({
  name: 'LandingView'
})
</script>

<style lang="scss" scoped>
</style>
