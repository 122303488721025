<template>
  <v-row
    class="ma-0"
    justify="center"
  >
    <v-expansion-panels
      v-model="panel"
      focusable
      multiple
    >
      <v-expansion-panel
        v-model="panel"
        v-for="(services, serviceGroupName) in groupServices"
        :key="serviceGroupName"
      >
        <v-expansion-panel-header
          class="text-h5"
        >
          {{ $t('services.'+serviceGroupName+'.name') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            dense
            class="pa-3"
          >
            <v-col
              v-for="(prices, serviceName) in services"
              :key="serviceGroupName+serviceName"
              cols="12"
              md="4"
              class="d-flex flex-column"
            >
              <SunaService
                :serviceGroupName="serviceGroupName"
                :serviceName="serviceName"
                :prices="prices"
                serviceImgHeight="300px"
              ></SunaService>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import SunaService from '@/components/SunaService.vue'
import {sunaGroupServices} from '@/appConfig'

export default ({
  name: 'ServicesView',
  data () {
    return {
      groupServices: sunaGroupServices,
      panel: [0]
    }
  },
  components: {
    SunaService
  }
})
</script>

<style scoped>
</style>
